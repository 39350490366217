import Vue from 'vue';
import VueSmoothScroll from 'v-smooth-scroll';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(VueSmoothScroll, {
  updateHistory: false,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
