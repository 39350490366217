





































import Vue from 'vue';

export default Vue.extend({
  name: 'Home',

  data() {
    return {
      scrolled: false,
    };
  },

  methods: {
    handleScroll() {
      const offset = window.pageYOffset || document.body.scrollTop;
      this.scrolled = offset > 64;
    },
  },

  mounted() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  },
});
